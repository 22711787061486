
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBell, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { Spinner } from 'react-bootstrap';
import { BASE_URL } from '../App';





function AppNavbar ({loggedIn  , auth  , clearToken , isDarkMode, toggleDarkMode , style} ) {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isBellDropdownOpen, setBellDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const isMobileDevice = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
      setScrollTop((window.scrollY / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) * 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
            'Authorization': `Bearer ${token}`,
          }
        };
        const response = await axios.get(`${BASE_URL}/api/mynotifications`, config);
        setNotifications(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (auth.reset_password === true) {
      navigate('/reset-password');
    }
  }, [auth, navigate]);

  const handleMenuToggle = () => setMenuOpen(prev => !prev);
  const toggleProfileDropdown = () => setProfileDropdownOpen(prev => !prev);
  const toggleBellDropdown = () => setBellDropdownOpen(prev => !prev);

  const handleProfileOutsideClick = (event) => {
    if (!event.target.closest('.user_img')) {
      setProfileDropdownOpen(false);
    }
  };

  const handleBellOutsideClick = (event) => {
    if (!event.target.closest('.bell')) {
      setBellDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isProfileDropdownOpen) {
      document.addEventListener('click', handleProfileOutsideClick);
    } else {
      document.removeEventListener('click', handleProfileOutsideClick);
    }

    if (isBellDropdownOpen) {
      document.addEventListener('click', handleBellOutsideClick);
    } else {
      document.removeEventListener('click', handleBellOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleProfileOutsideClick);
      document.removeEventListener('click', handleBellOutsideClick);
    };
  }, [isProfileDropdownOpen, isBellDropdownOpen]);

    return (   
      <>

      <nav
      className={` nav-bar ${isSticky ? 'sticky' : ''} ${isMenuOpen ? 'open' : ''}   ${style ? 'dark-mode' : ''}`} >
 
     <div className="container">
   
      {
        loggedIn ?
        ""
        :
        <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
        <span className="bar"></span>
  <span className="bar"></span>
  <span className="bar"></span>
</div>
      }

<div className='d-flex align-items-center mobile-layout'>
      <div className={`dark-mode-toggle ${isDarkMode ? 'active' : ''}`} onClick={toggleDarkMode}>
  <FontAwesomeIcon icon={faLightbulb} className="lamp-icon ms-lg-2 me-2" style={{ width: '30px' }}/>
</div>
      <Link to="/" className="logo">
        <img className='dark-logo' src='/images/Artboard 3.2.png' loading='lazy' alt=""/>
        <img className='light-logo'  src='/images/Artboard 3.png' loading='lazy' alt=''/>
   
      </Link>
    </div>
      
      <ul className={`${isMenuOpen ? 'show' : ''}`}>
          {
            loggedIn ?
            <>
          
          <div className='bell-img'>
           {/* User Profile Dropdown */}
      <img
        src="/images/profile (1)..webp"
        alt=""
        className="user_img"
        loading="lazy"
        onClick={toggleProfileDropdown}
      />
      {isProfileDropdownOpen && (
        <div className={`drop ${isProfileDropdownOpen ? 'show' : ''}`}>
          <Link>
            <span className='me-2' style={{ whiteSpace: 'nowrap' }}>{auth.fname}</span> <span className='d-flex align-items-center flex-row-reverse'>  اهلاً <span className='me-1'>وسهلاً</span> </span>
          </Link>
          <Link to="/profile" onClick={() => setProfileDropdownOpen(false)}> حسابي <img src='/images/profile-removebg-preview..webp' loading="lazy" alt="" /></Link>
          <Link to="/profile/courses" onClick={() => setProfileDropdownOpen(false)}> كورساتي <img src='/images/profile-removebg-preview..webp' loading="lazy" alt="" /></Link>
          <Link onClick={clearToken}> تسجيل الخروج <img src='/images/logout-removebg-preview..webp' alt="" loading="lazy"/></Link>
        </div>
      )}

      {/* Bell Icon Dropdown */}
          <div className='div-bell'>
          <FontAwesomeIcon
        icon={faBell}
        className='bell'
        style={{ color: '#f43f5e' }}
        onClick={toggleBellDropdown}
          />
       
          </div>
        
     
          {isBellDropdownOpen && (
  <div className={`drop ${isBellDropdownOpen ? 'show' : ''}`}>
      {
        isLoading
        ?
        <Link className='no-noti' >           <Spinner animation="border" role="status" className='spinners'>
        <span className="visually-hidden">Loading...</span>
        </Spinner>  <span className='ms-1 me-1'> الاشعارات </span>  يتم تحميل بيانات</Link>

        :
        notifications.length > 0 ? (
          notifications.map((noti) => (
            <Link to={`/contentcourse/${noti.course_id}`} style={isMobileDevice ? {} : { whiteSpace: 'nowrap' }} key={noti.id}>
             <FontAwesomeIcon icon={faArrowLeft} className="back-arrow me-4" /> {/* Left arrow icon */}
              {noti.data}
            </Link>
          ))
        ) : (
          <Link className='no-noti' >  لك حتي الأن  <span className='ms-1 me-1'> اشعارات </span>  لا توجد </Link>
        )}
      
  </div>
)}

    <div className='wallet'>
      <Link to={`/charge-wallet`}>
      <img src='/images/wallet.png' alt='' loading='lazy'/>
      </Link>

    </div>
            </div>  

            </>

            :
    
            <>
          <li>
          <Link to="/loginuser" className="login"> 
          <span className={`first-span ${style ? 'dark-mode' : ''}`}> سجل </span><span className="child-span"> دخولك </span> 
          <img src="/images/next..webp" alt="" loading="lazy"/>
          </Link>
          </li>
          <li>  
          <Link to="/registeruser" className="register"> 
          <span className="text-center me-lg-3" >!  اشترك معانا يلا  </span>
          <img src="/images/add-group..webp" alt="" loading="lazy"/>
          </Link>

          </li> 
            </>

          }
      </ul>
     </div>
     
     <div className={`scroller ${isSticky ? 'sticky' : ''}`}>
        <div
          className="scrollerStyle"
          style={{ width: `${scrollTop}%` }}
        ></div>
      </div>
   
    </nav>
      </>
    
    )
}



export default AppNavbar;







