          import axios from "axios";
          import { useCallback, useState } from "react";
          import { useEffect } from "react";
          import { Col, Row } from "react-bootstrap";
          import { Link, useNavigate, useParams } from "react-router-dom";
          import swal from "sweetalert";
          import { BASE_URL, Image_URL } from "../../App";
          import '../../styles/years.css'
          import ScrollTopButton from "../ScrollTop";
import CardNot from "../Cardnotlogin";

          function Courses({loggedIn ,setLoggedIn , style}) {
            const navigate = useNavigate()

              const {id} = useParams()

              const [cards, setCards] = useState(() => []);
              const [levelTitle, setLevelTitle] = useState(() => "");
              const [isLoading, setIsLoading] = useState(true);
              
        useEffect(() => {
          
          window.scrollTo(0, 0);
        }, []);

              
                // الريكويست الخاص بعرض الكورسات
          //     const fetchData = useCallback(async () => {
          //       try {
          //           const token = localStorage.getItem('token');
          //           const config = {
          //             headers: {
          //               'Content-Type': 'application/json',
          //               "ngrok-skip-browser-warning": "any",
          //               'Authorization': `Bearer ${token}`,
          //             }
          //           };
          //           const response = await axios.get(`${BASE_URL}/api/show-level-courses/${id}`, config);
          //           setLevelTitle(response?.data);
          //           setCards(response?.data?.data);
          //           setIsLoading(false); 

          //       } catch (error) {
          //         setIsLoading(false); 
          //       }
          //   }, [id]);
          
          //   useEffect(() => {
          //     fetchData();
          //     window.scrollTo(0, 0);

          // }, [fetchData, id]);


          const [cardsOnline , setCardsOnline] = useState([]);
          const [cardsCenter , setCardsCenter] = useState([]);

          const fetchDataOnline = async () => {
            const token = localStorage.getItem('token');
            const config = {
            headers: {
            'Content-Type': 'application/json',
            "ngrok-skip-browser-warning": "any",
            'Authorization': `Bearer ${token}`,
            }
            };
  
            try {
            const response = await axios.get(`${BASE_URL}/api/show-level-online-courses/${id}`, config);
            setLevelTitle(response.data);
            setCardsOnline(response.data.data);
            console.log(response.data.data);
            setIsLoading(false); 
            } catch (error) {
            setIsLoading(false); 
            }
            }

            const fetchDataCenter = async () => {
              const token = localStorage.getItem('token');
              const config = {
              headers: {
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "any",
              'Authorization': `Bearer ${token}`,
              }
              };
          
              try {
              const response = await axios.get(`${BASE_URL}/api/show-level-offline-courses/${id}`, config);
              setLevelTitle(response.data);
              setCardsCenter(response.data.data);
              // console.log(response.data.data);
              setIsLoading(false); 
              } catch (error) {
              setIsLoading(false); 
              }
              }

              useEffect(() => {
                  fetchDataOnline();
                  fetchDataCenter();
              }, []);



          if (isLoading) {
            return <div className={`main-loading ${style ? 'dark-mode' : ''}`}>
              <div className="loading-course"> 
            <h2>  .... جاري تحميل الكورسات</h2>
            </div>; 
            </div>
          }
          
          

              return (
                <>  
          
                 
                  <div className={`coursess ${style ? 'dark-mode' : ''}`}>
                 
                  {
                    id === '3'
                    ?
                    <>
                       <Row className="flex-row-reverse">
                    <div className="title">
                      <h2> كورسات <span>الأونلاين</span> </h2>
                    </div>
            {cardsOnline.length > 0 ? (
              cardsOnline.map((cardonline) => (
                <Col lg={4} key={cardonline.id}>
                  <CardNot cardonline={cardonline} />
                </Col>
              ))
            ) : (
              <div className={`no-courses ${style ? 'dark-mode' : ''}`}>
                <h2> !لا تتوفر كورسات حتى الآن </h2>
              </div>
            )}

                     </Row>
                     
                     <Row className="flex-row-reverse mt-5">
<div className="title">
                      <h2> كورسات <span>السنتر</span> </h2>
                    </div>

            {cardsCenter.length > 0 ? (
              cardsCenter.map((cardcenter) => (
                <Col lg={4} key={cardcenter.id}>
                  <CardNot cardcenter={cardcenter} />
                </Col>
              ))
            ) : (
              <div className={`no-courses ${style ? 'dark-mode' : ''}`}>
                <h2> !لا تتوفر كورسات حتى الآن </h2>
              </div>
            )}
                      </Row>
                    </>
                    :
                    <Row className="flex-row-reverse">
                    <div className="title">
                      <h2> كورسات <span>الأونلاين</span> </h2>
                    </div>
            {cardsOnline.length > 0 ? (
              cardsOnline.map((cardonline) => (
                <Col lg={4} key={cardonline.id}>
                  <CardNot cardonline={cardonline} />
                </Col>
              ))
            ) : (
              <div className={`no-courses ${style ? 'dark-mode' : ''}`}>
                <h2> !لا تتوفر كورسات حتى الآن </h2>
              </div>
            )}

                     </Row>
                  }

                    </div>
                            < ScrollTopButton />
                </>
              )
          }
          export default Courses;