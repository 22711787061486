import { Link } from "react-router-dom";
import '../styles/card.css';
import { Image_URL } from "../App";

function CardNot({ cardonline, cardcenter }) {
  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC', numberingSystem: 'arab' };
      const formatter = new Intl.DateTimeFormat('ar-EG', options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    } catch (e) {
      console.error('Error formatting date', e);
      return '';
    }
  }

  const cardData = cardonline || cardcenter;

  return (
    <>
      <div className="card mb-4">
        <div className="image">
          <img src={`${Image_URL}/storage/${cardData.attributes.img}`} alt="" loading="lazy" />
        </div>
        <div className="about">
          <div className="details">
            <h3> {cardData.attributes.name}</h3>
            <p> {cardData.attributes.description}</p>
          </div>
          <div className={`price ${cardData.attributes.old_price !== '' ? 'red-bg' : ''}`}>
            {cardData.attributes.price === '0.00' ? (
              <span className="free"> هذا الكورس مجاني </span>
            ) : (
              <>
<span className="ms-1">
  {cardData.attributes.old_price === ''
    ? cardData.attributes.price
    : (
      <>
        <del>{cardData.attributes.old_price}.00</del> {cardData.attributes.price}
        <span className='ms-3'>  {`${(((cardData.attributes.old_price - cardData.attributes.price) * 100 ) / cardData.attributes.old_price).toFixed(0)}`} % اوفر</span>
      </>
    )
  }
</span>                <span> جنيهاَ </span>
              </>
            )}
          </div>
          <div className="dates mt-4">
            <div className="date">
              <img src="/images/plus..webp" alt="" loading="lazy" />
              <span className="ms-2"> {formatDate(cardData.attributes.created_at)} </span>
            </div>
            <div className="date mt-2">
              <img src="/images/update..webp" alt="" loading="lazy" />
              <span className="ms-2"> {formatDate(cardData.attributes.updated_at)} </span>
            </div>
          </div>
          <div className="btns">
            <Link to="/registeruser"> اشترك الأن </Link>
            <Link to={`/course-content/${cardData.id}`}> الدخول للكورس </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardNot;
